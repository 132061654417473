import * as React from 'react';
import Layout from '../components/Layout';
// import EventsLocation from '../components/EventsLocation';
import { Kaltura } from '@bgea-js/design-library';

const meta = {
  title: 'Events',
  description: '',
};

const EventsPage = () => (
  <Layout meta={meta}>
    <div id="eventPromo" className="videoContainer pt-6">
      <section className="video">
        <div className="flexVideo">
          <Kaltura id="1_pd373k0m" />
        </div>
      </section>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#f3f3f3"
        fill-opacity="1"
        d="M0,64L48,58.7C96,53,192,43,288,69.3C384,96,480,160,576,160C672,160,768,96,864,69.3C960,43,1056,53,1152,80C1248,107,1344,149,1392,170.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
      ></path>
    </svg>
    {/* <EventsLocation /> */}
  </Layout>
);

export default EventsPage;
